<template>
  <div>
    <modal-listar-numero-pedidos :listaNumeroPedido="listaNumeroPedido" />
    <div></div>
    <div class="content">
      <div v-if="type == 'linha1' || type == 'admin'">
        -->
        <!-- <AguardandoProdAtrasado />
        <AguardandoProd /> -->
        -->
      </div>
      <div class="box-content">
        <div class="containerDashboad">
          <b-row>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="pedRealizados">
              <a href="#" class="data-card realizados">
                <h3
                  class="h3card"
                  @click="showListaNumeroPedidos(pedRealizados)"
                >
                  {{ pedRealizados.length }}
                </h3>
                <h4 class="h4card">Ped. Despachados</h4>
                <p class="pCard">Pedidos Despachados</p>
              </a>
            </b-col>

            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="metaProd">
              <a
                href="#"
                class="data-card"
                @click="showListaNumeroPedidos(metaDia)"
              >
                <h3 class="h3card">
                  {{ metaDia.length }}
                </h3>
                <h4 class="h4card">Meta Dia</h4>
                <p class="pCard">Pedidos do dia.</p>
              </a>
            </b-col>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3">
              <a
                href="#"
                class="data-card"
                @click="showListaNumeroPedidos(metaHora)"
              >
                <h3 class="h3card">
                  {{ Math.ceil(metaHora.length / 8) }}
                </h3>
                <h4 class="h4card">Meta Hora</h4>
                <p class="pCard">Pedidos por Hora.</p>
              </a>
            </b-col>

            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="paineis">
              <a
                href="#"
                class="data-card paineis"
                @click="showListaNumeroPedidos(linha1)"
              >
                <h3 class="h3card paineis">
                  {{ linha1.length }}
                </h3>
                <h4 class="h4card paineis">Paineis</h4>
                <p class="pCard paineis">Paineis Produzidos no dia</p>
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="pedAtrasados">
              <a
                href="#"
                class="data-card atrasados"
                @click="showListaNumeroPedidos(pedAtrasados)"
              >
                <h3 class="h3card" style="">
                  {{ pedAtrasados.length }}
                </h3>
                <h4 class="h4card">Ped. Atrasados</h4>
                <p class="pCard">Pedidos Atrasados</p>
              </a>
            </b-col>

            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="pedFaturado">
              <a
                href="#"
                class="data-card"
                @click="showListaNumeroPedidos(pedFaturados)"
              >
                <h3 class="h3card">
                  {{ pedFaturados.length }}
                </h3>
                <h4 class="h4card">Ped. Faturados</h4>
                <p class="pCard">Pedidos Faturados</p>
              </a>
            </b-col>

            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="CabosePerfils">
              <a
                href="#"
                class="data-card cabos"
                @click="showListaNumeroPedidos(linha2)"
              >
                <h3 class="h3card paineis">
                  {{ linha2.length }}
                </h3>
                <h4 class="h4card paineis">Cabos</h4>
                <p class="pCard paineis">Cabos Produzidos no dia</p>
              </a>
            </b-col>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3">
              <a
                href="#"
                class="data-card perfis"
                @click="showListaNumeroPedidos(linha4)"
              >
                <h3 class="h3card paineis">
                  {{ linha4.length }}
                </h3>
                <h4 class="h4card paineis">Perfis</h4>
                <p class="pCard paineis">Perfis Produzidos no dia</p>
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="confFinal">
              <a
                href="#"
                class="data-card confFinal"
                @click="showListaNumeroPedidos(confFinal)"
              >
                <h3 class="h3card paineis">
                  {{ confFinal.length }}
                </h3>
                <h4 class="h4card paineis">Conf. Final</h4>
                <p class="pCard paineis">Conferências Finais feitas no dia</p>
              </a>
            </b-col>

            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="confAc">
              <a
                href="#"
                class="data-card confAc"
                @click="showListaNumeroPedidos(linha3Conf)"
              >
                <h3 class="h3card paineis">
                  {{ linha3Conf.length }}
                </h3>
                <h4 class="h4card paineis">Conf. AC</h4>
                <p class="pCard paineis">Conferências AC feitas no dia</p>
              </a>
            </b-col>

            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="diversos">
              <a
                href="#"
                class="data-card diversos"
                @click="showListaNumeroPedidos(linha3)"
              >
                <h3 class="h3card paineis">
                  {{ linha3.length }}
                </h3>
                <h4 class="h4card paineis">Diversos</h4>
                <p class="pCard paineis">Diversos Produzidos no dia</p>
              </a>
            </b-col>

            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="metaProd">
              <a
                href="#"
                class="data-card embarque"
                @click="showListaNumeroPedidos(embarque)"
              >
                <h3 class="h3card">
                  {{ embarque.length }}
                </h3>
                <h4 class="h4card">Aguardando Embarque</h4>
                <p class="pCard">Pedidos Aguardando Embarque.</p>
              </a>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="metaProd">
              <a
                href="#"
                class="data-card cabos"
                @click="showListaNumeroPedidos(ploomesAguardandoProducao)"
              >
                <h3 class="h3card">
                  {{ ploomesAguardandoProducao.length }}
                </h3>
                <h4 class="h4card">Ped. Diversos Aguardando Produção</h4>
                <p class="pCard">Ped. Diversos Aguardando Produção.</p>
              </a>
            </b-col>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="metaProd">
              <a
                href="#"
                class="data-card paineis"
                @click="showListaNumeroPedidos(ploomesEmLinhaProducao)"
              >
                <h3 class="h3card">
                  {{ ploomesEmLinhaProducao.length }}
                </h3>
                <h4 class="h4card">Ped. Diversos linha Produção</h4>
                <p class="pCard">Ped. Diversos Em Linha Produção.</p>
              </a>
            </b-col>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="metaProd">
              <a
                href="#"
                class="data-card realizados"
                @click="showListaNumeroPedidos(ploomesAguardandoEmbarque)"
              >
                <h3 class="h3card">
                  {{ ploomesAguardandoEmbarque.length }}
                </h3>
                <h4 class="h4card">Ped. Diversos Aguardando Embarque</h4>
                <p class="pCard">Ped. Diversos Aguardando Embarque.</p>
              </a>
            </b-col>
            <b-col cols="12" sm="12" md="3" lg="3" xl="3" id="confFinal">
              <a
                href="#"
                class="data-card confAc"
                @click="showListaNumeroPedidos(getInversores)"
              >
                <h3 class="h3card">
                  {{ getInversores.length }}
                </h3>
                <h4 class="h4card">Ped. Inversores</h4>
                <p class="pCard">Inversores Produzidos no dia.</p>
              </a>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <!-- </div>  -->
  </div>
</template>

<script>
// Remover chart.js e vue-chartjs do package.json -- Erick 05.09.2023
// import { Doughnut } from "vue-chartjs";
import { DashboardRepository } from "@/repositories/v1/DashboardRepository";
import ModalListarNumeroPedidos from "../components/ModalListarNumeroPedidos.vue";

export default {
  name: "Dashboard",
  components: { ModalListarNumeroPedidos },
  // extends: Doughnut,
  data() {
    return {
      type: null,
      status: "Desativado",
      // dashboardData: [],
      metaDia: [],
      embarque: [],
      metaHora: [],
      pedRealizados: [],
      pedAtrasados: [],
      pedFaturados: [],
      linha1: [],
      linha2: [],
      linha3: [],
      linha3Conf: [],
      linha4: [],
      confFinal: [],
      ploomesAguardandoProducao: [],
      ploomesEmLinhaProducao: [],
      ploomesAguardandoEmbarque: [],
      timer: "",
      listaNumeroPedido: [],
      dashboardRepository: DashboardRepository.build(),
    };
  },
  mounted() {
    this.getDashboardData();
    this.dashboardRepository = DashboardRepository.build();
    this.timer = setInterval(this.getDashboardData, 120000);
  },
  methods: {
    showListaNumeroPedidos(listaNumeroPedidos) {
      // console.log("ak");
      this.listaNumeroPedido = listaNumeroPedidos;
      // console.log("ak");
      this.$bvModal.show("modal-listar-numeros-pedidos");
    },
    getDashboardData() {
      if (this.$route.path === "/dashboard") {
        this.getMetaDia();
        this.getMetaHora();
        this.getPedRealizados();
        this.getPedAtrasados();
        this.getPedFaturados();
        this.getLinha1();
        this.getLinha2();
        this.getLinha3();
        this.getLinha3Conf();
        this.getLinha4();
        this.getConfFinal();
        this.getAguardEmbarque();
        this.getPloomesAguardandoProducao();
        this.getPloomesEmLinhaDeProducao();
        this.getPloomesAguardandoEmbarque();
        this.getInversores();
      }
    },
    async getMetaDia() {
      const response = await this.dashboardRepository.getMetaDia();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.metaDia = response.data.total_pedidos[0].METADIA;
      }
    },
    async getMetaHora() {
      const response = await this.dashboardRepository.getMetaHora();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.metaHora = response.data.total_pedidos[0].METAHORA;
      }
    },
    async getPedRealizados() {
      const response = await this.dashboardRepository.getConcluido();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.pedRealizados = response.data.total_pedidos[0].CONCLUIDO;
      }
    },
    async getPedAtrasados() {
      const response = await this.dashboardRepository.getAtrasados();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.pedAtrasados = response.data.atrasados;
      }
    },
    async getPedFaturados() {
      const response = await this.dashboardRepository.getFaturados();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.pedFaturados = response.data.total_pedidos[0].FATURADOS;
      }
    },
    async getLinha1() {
      const response = await this.dashboardRepository.getPaineis();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.linha1 = response.data.total_pedidos[0].PAINEIS;
      }
    },
    async getLinha2() {
      const response = await this.dashboardRepository.getCabos();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.linha2 = response.data.total_pedidos[0].CABO;
      }
    },
    async getLinha3() {
      const response = await this.dashboardRepository.getDiversos();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.linha3 = response.data.total_pedidos[0].DIVERSOS;
      }
    },
    async getLinha3Conf() {
      const response = await this.dashboardRepository.getConferenciaAc();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.linha3Conf = response.data.total_pedidos[0].CONFERENCIAAC;
      }
    },
    async getLinha4() {
      const response = await this.dashboardRepository.getPerfis();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.linha4 = response.data.total_pedidos[0].PERFIL;
      }
    },
    async getConfFinal() {
      const response = await this.dashboardRepository.getConferenciaFinal();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.confFinal = response.data.total_pedidos[0].CONFERENCIAFINAL;
      }
    },
    async getAguardEmbarque() {
      const response = await this.dashboardRepository.getAguardandoEmbarque();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.embarque = response.data.data[0].total;
      }
    },
    async getPloomesAguardandoProducao() {
      const response =
        await this.dashboardRepository.getPloomesAguardandoProducao();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.ploomesAguardandoProducao = response.data.data[0].total;
      }
    },
    async getPloomesEmLinhaDeProducao() {
      const response =
        await this.dashboardRepository.getPloomesEmLinhaDeProducao();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.ploomesEmLinhaProducao = response.data.data[0].total;
      }
    },
    async getPloomesAguardandoEmbarque() {
      const response =
        await this.dashboardRepository.getPloomesAguardandoEmbarque();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.ploomesAguardandoEmbarque = response.data.data[0].total;
      }
    },
    async getInversores() {
      const response = await this.dashboardRepository.getInversores();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        this.getInversores = response.data.data[0].total;
      }
    },
  },
};
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}

.data-card {
  display: flex;
  flex-direction: column;
  // max-width: 20.75em;
  max-height: 20.75em;
  min-height: 20.75em;
  overflow: hidden;
  border-radius: 0.5em;
  text-decoration: none;
  background: white;
  margin: 0.5em;
  padding: 2.75em 2.5em;
  box-shadow: 0 0.5em 1.5em 0.5em rgb(0 0 0 / 7%);
  transition: transform 0.45s ease, background 0.45s ease;

  &:hover {
    background: #2eb03b;
    transform: scale(1.02);

    .h3card {
      color: #ffffff;
      border-bottom-color: #ffffff;
    }

    .h4card {
      color: #ffffff;
    }

    .pCard {
      opacity: 1;
      transform: none;
    }

    .link-text {
      color: #ffffff;

      svg {
        animation: point 1.25s infinite alternate;

        path {
          fill: #ffffff;
        }
      }
    }
  }
}
.data-card.paineis {
  background: #3699ff;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #3699ff; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #3699ff;
    transform: scale(1.02);
  }
}

.data-card.embarque {
  background: #ffa500;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #3699ff; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #3699ff;
    transform: scale(1.02);
  }
}

.data-card.cabos {
  background: #721212;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #721212; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #721212;
    transform: scale(1.02);
  }
}
.data-card.perfis {
  background: #a06c03;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #a06c03; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #a06c03;
    transform: scale(1.02);
  }
}
.data-card.realizados {
  background: #2eb03b;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #2eb03b; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #2eb03b;
    transform: scale(1.02);
  }
}
.data-card.diversos {
  background: #3f3f3f;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #3f3f3f; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #3f3f3f;
    transform: scale(1.02);
  }
}
.data-card.atrasados {
  background: #af1a1a;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #af1a1a; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #af1a1a;
    transform: scale(1.02);
  }
}
.data-card.confAc {
  background: #6d6bdf;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #6d6bdf; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #6d6bdf;
    transform: scale(1.02);
  }
}
.data-card.confFinal {
  background: #4fa556;
  .h3card {
    color: #ffffff !important;
    border-bottom: 2px solid #4fa556; //seta
  }
  .pCard {
    color: #ffffff !important;
  }
  .h4card {
    color: #ffffff !important;
  }
  &:hover {
    background: #4fa556;
    transform: scale(1.02);
  }
}

.h3card {
  color: #2e2e2e;
  font-size: 44px;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 0.5em;
  margin: 0 0 0.142857143em;
  border-bottom: 2px solid #2eb03b; //seta
  transition: color 0.45s ease, border 0.45s ease;
}

.h4card {
  color: #2e2e2e;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  margin: 0 0 1.777777778em;
  transition: color 0.45s ease;
}

.pCard {
  opacity: 0;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.8;
  margin: 0 0 1.25em;
  transform: translateY(-1em);
  transition: opacity 0.45s ease, transform 0.5s ease;
}
@media only screen and (max-width: 1100px) {
  .containerDashboad {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "pedRealizados metaProd paineis"
      "pedAtrasados pedFaturado CabosePerfils "
      " confFinal confAc diversos";
  }
}
@keyframes point {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.125em);
  }
}
</style>
