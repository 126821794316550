<template>
  <b-modal
    data-bv
    id="modal-listar-numeros-pedidos"
    body-class="rounded"
    hide-header
    centered
    hide-footer
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">Números dos Pedidos</h4>
      </div>
      <hr />
      <div class="row justify-content-center">
        <div v-for="(numeroPedido, index) in listaNumeroPedido" :key="index">
          <p class="col col-12">{{ numeroPedido }},</p>
        </div>
      </div>
      <div class="row text-center justify-content-center">
        <button class="btn btn-info" @click="closeModal()">OK</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalListarNumeroPedidos",
  data: () => ({}),
  created() {},
  props: {
    listaNumeroPedido: { Type: Array, required: true },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-listar-numeros-pedidos");
    },
  },
  computed: {},
};
</script>

<style scoped>
.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}
</style>
