import { HttpRestService } from "@/services/http/HttpRestService";

export class DashboardRepository {
  static build() {
    return new DashboardRepository();
  }

  getMetaDia() {
    return HttpRestService.get(`/api/dashboard/meta-dia`);
  }

  getMetaHora() {
    return HttpRestService.get(`/api/dashboard/meta-hora`);
  }

  getConcluido() {
    return HttpRestService.get(`/api/dashboard/concluido`);
  }

  getFaturados() {
    return HttpRestService.get(`/api/dashboard/faturados`);
  }

  getAtrasados() {
    return HttpRestService.get(`/api/dashboard/atrasados`);
  }

  getPaineis() {
    return HttpRestService.get(`/api/dashboard/paineis`);
  }

  getCabos() {
    return HttpRestService.get(`/api/dashboard/cabo`);
  }

  getPerfis() {
    return HttpRestService.get(`/api/dashboard/perfil`);
  }

  getDiversos() {
    return HttpRestService.get(`/api/dashboard/diversos`);
  }

  getConferenciaAc() {
    return HttpRestService.get(`/api/dashboard/conferencia-ac`);
  }

  getConferenciaFinal() {
    return HttpRestService.get(`/api/dashboard/conferencia-final`);
  }

  getAguardandoEmbarque() {
    return HttpRestService.get(`/api/v2/dashboard/embarque`);
  }

  getPloomesAguardandoProducao() {
    return HttpRestService.get("/api/dashboard/ploomes/aguardando-producao");
  }

  getPloomesEmLinhaDeProducao() {
    return HttpRestService.get("/api/dashboard/ploomes/linha-producao");
  }

  getPloomesAguardandoEmbarque() {
    return HttpRestService.get("/api/dashboard/ploomes/aguardando-embarque");
  }
  getInversores() {
    return HttpRestService.get(
      "/api/v2/dashboard/pedidos-com-inversores-pendentes"
    );
  }
}
